import Folourgo_Default from '../assets/folourgo/Folourgo_Default.jpeg';
import Folourgo_2 from '../assets/folourgo/Folourgo_2.jpeg';
import Folourgo_3 from '../assets/folourgo/Folourgo_3.jpeg';
import Folourgo_remastered from '../assets/folourgo/Image site 6-01.jpg'
import Folourgo_remastered_3 from '../assets/folourgo/Folourgo_Main.jpeg'
import Folourgo_remastered_4 from '../assets/folourgo/Folourgo_4.jpeg'
import Folourgo_remastered_2 from '../assets/folourgo/Image site 3-01.jpg'
import Agence_Succes_1  from '../assets/AgenceSucces/slide imci.jpeg'
export const carousel_imgFade = [
    { src: Agence_Succes_1, legend: "Agence Succès" },
    // { src: Folourgo_remastered, legend: "Folourgo Construction" },
    { src: Folourgo_remastered_2, legend: "Folourgo Construction" },
    // { src: Folourgo_remastered_3, legend: "Folourgo Construction" },
    { src: Folourgo_remastered_4, legend: "Folourgo Construction" },

]
